<template>
  <div id="internalAuditCheck">
    <el-dialog
      :title="internalAuditCheckFormTitle"
      width="1200px"
      :visible.sync="internalAuditCheckDialogVisible"
      :close-on-click-modal="false"
      @close="internalAuditCheckDialogClose"
    >
      <el-form
        ref="internalAuditCheckFormRef"
        :model="internalAuditCheckForm"
        :rules="internalAuditCheckFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="受审核部门" prop="dept">
              <el-input v-model="internalAuditCheckForm.dept" placeholder="请输入受审核部门" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="接待人" prop="receiver">
              <el-input v-model="internalAuditCheckForm.receiver" placeholder="请输入接待人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="日期" prop="checkDate">
              <el-date-picker v-model="internalAuditCheckForm.checkDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="内审员" prop="internalAuditor">
              <el-input v-model="internalAuditCheckForm.internalAuditor" placeholder="请输入内审员" clearable />
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="条款及要求" prop="requirement">
              <el-input v-model="internalAuditCheckForm.requirement" placeholder="请输入条款及要求" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="审查方法和凭证" prop="methodEvidence">
              <el-input v-model="internalAuditCheckForm.methodEvidence" placeholder="请输入审查方法和凭证" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="检查记录" prop="record">
              <el-input v-model="internalAuditCheckForm.record" placeholder="请输入检查记录" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="Y/N" prop="yn">
              <el-select v-model="internalAuditCheckForm.yn" placeholder="请输入Y/N">
                 <el-option key="1" label="Y" :value="1" />
                 <el-option key="2" label="N" :value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="internalAuditCheckDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="internalAuditCheckFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="internalAuditCheckPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="dept" label="受审核部门" />
      <el-table-column prop="receiver" label="接待人" />
      <el-table-column prop="checkDate" label="日期" />
      <el-table-column prop="internalAuditor" label="内审员" />
      <el-table-column prop="requirement" label="条款及要求" />
      <el-table-column prop="methodEvidence" label="审查方法和凭证" />
      <el-table-column prop="record" label="检查记录" />
      <el-table-column prop="yn" label="Y/N" >
        <template slot-scope="scope">
          <span v-if="scope.row.yn == 1">Y</span>
          <span v-if="scope.row.yn == 2">N</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="internalAuditCheckPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addInternalAuditCheck, deleteInternalAuditCheck, updateInternalAuditCheck, selectInternalAuditCheckInfo, selectInternalAuditCheckList } from '@/api/quality/internalAuditChecklist'

export default {
  data () {
    return {
      internalAuditCheckDialogVisible: false,
      internalAuditCheckFormTitle: '',
      internalAuditCheckForm: {
        id: '',
        dept: '',
        receiver: '',
        checkDate: '',
        internalAuditor: '',
        requirement: '',
        methodEvidence: '',
        record: '',
        yn: ''
      },
      internalAuditCheckFormRules: {
        name: [{ required: true, message: '名称不能为空', trigger: ['blur', 'change']}]
      },
      internalAuditCheckPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectInternalAuditCheckList(this.searchForm).then(res => {
      this.internalAuditCheckPage = res
    })
  },
  methods: {
    internalAuditCheckDialogClose () {
      this.$refs.internalAuditCheckFormRef.resetFields()
      this.internalAuditCheckForm.id = ''
    },
    internalAuditCheckFormSubmit () {
      if (this.internalAuditCheckFormTitle === '内部审核检查表') {
        this.internalAuditCheckDialogVisible = false
        return
      }
      this.$refs.internalAuditCheckFormRef.validate(async valid => {
        if (valid) {
          if (this.internalAuditCheckFormTitle === '新增内部审核检查表') {
            await addInternalAuditCheck(this.internalAuditCheckForm)
          } else if (this.internalAuditCheckFormTitle === '修改内部审核检查表') {
            await updateInternalAuditCheck(this.internalAuditCheckForm)
          }
          this.internalAuditCheckPage = await selectInternalAuditCheckList(this.searchForm)
          this.internalAuditCheckDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.internalAuditCheckFormTitle = '新增内部审核检查表'
      this.internalAuditCheckDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteInternalAuditCheck(row.id)
        if (this.internalAuditCheckPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.internalAuditCheckPage = await selectInternalAuditCheckList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.internalAuditCheckFormTitle = '修改内部审核检查表'
      this.internalAuditCheckDialogVisible = true
      this.selectInternalAuditCheckById(row.id)
    },
    handleInfo (index, row) {
      this.internalAuditCheckFormTitle = '内部审核检查表详情'
      this.internalAuditCheckDialogVisible = true
      this.selectInternalAuditCheckById(row.id)
    },
    selectInternalAuditCheckById (id) {
      selectInternalAuditCheckInfo(id).then(res => {
        this.internalAuditCheckForm.id = res.id
        this.internalAuditCheckForm.dept = res.dept
        this.internalAuditCheckForm.receiver = res.receiver
        this.internalAuditCheckForm.checkDate = res.checkDate
        this.internalAuditCheckForm.internalAuditor = res.internalAuditor
        this.internalAuditCheckForm.requirement = res.requirement
        this.internalAuditCheckForm.methodEvidence = res.methodEvidence
        this.internalAuditCheckForm.record = res.record
        this.internalAuditCheckForm.yn = res.yn
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectInternalAuditCheckList(this.searchForm).then(res => {
        this.internalAuditCheckPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectInternalAuditCheckList(this.searchForm).then(res => {
        this.internalAuditCheckPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectInternalAuditCheckList(this.searchForm).then(res => {
        this.internalAuditCheckPage = res
      })
    }
  }
}
</script>

<style>
</style>
